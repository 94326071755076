import { FC, PropsWithChildren } from 'react';
import { PermissionResourceAndLevel } from '@les-sherpas/sherpas-toolbox';

import useAuthentication from '@/components/Authentication/useAuthentication';

type Props = {
  permission: PermissionResourceAndLevel;
  isAppliedOnlyWhenConnected?: boolean;
};

const Permission: FC<PropsWithChildren<Props>> = ({
  children,
  permission,
  isAppliedOnlyWhenConnected = false,
}) => {
  const { authentication } = useAuthentication();

  const isAllowedToAccessResourceAsUnauthenticatedUser =
    isAppliedOnlyWhenConnected && !authentication?.isAuthenticated;

  const isAllowedToAccessResourceAsAuthenticatedUser = permission
    ? authentication?.authData?.permissions?.includes(permission)
    : true;

  const isAllowedToAccessResource =
    isAllowedToAccessResourceAsUnauthenticatedUser ||
    isAllowedToAccessResourceAsAuthenticatedUser;

  return isAllowedToAccessResource ? <>{children}</> : null;
};

export default Permission;
