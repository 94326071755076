import { FC } from 'react';
import { Typography } from '@mui/material';

import IconMarianne from '@/shared/TaxReductionLabel/IconMarianne';

import useStyles from './styles';

type Props = {
  text: string;
};

const TaxReductionLabel: FC<Props> = ({ text }) => {
  const { classes } = useStyles();

  return (
    <Typography variant="bodyMd" component="span" className={classes.container}>
      {text}
      <IconMarianne />
    </Typography>
  );
};

export default TaxReductionLabel;
